const config = {
    api: {
        url: '/engine/api.php',
        baseUrl: '',
    },
    // api: {
    //     url: 'http://localhost:8888/engine/api.php',
    //     baseUrl: 'http://localhost:8888',
    // },
    // api: {
    //     url: 'https://badland.su/engine/api.php',
    //     baseUrl: 'https://badland.su',
    // },
}

export default config;